<template>
  <div>
    <v-data-table
        class="pa-1 logs-table"
        :no-data-text="$t('no-data-available')"
        :loading-text="$t('loading')"
        :headers="headers"
        :headers-length="headers.length"
        :items="filteredLogs"
        :loading="loader"
        sort-by="id"
        :search="appBarSearch"
        fixed-header
        dense
        sort-desc
        :height="pageHeight(150)"
        :footer-props= "getDataTableFooterProps('systemLogsTable')"
        :items-per-page="logsPerPage"
        :expanded.sync="expanded"
        :single-expand="true"
        @update:items-per-page="onRowsPerPageChange"
    >
      <template v-slot:item="{ item }">
        <tr @click="expand(item);" class="pointer">
          <td style="height:30px !important;" class="table-font-size px-2 py-0">
            <div class="d-flex">
              <v-icon style="font-size:18px !important;" class="mr-2" color="green" v-if="item.level === 'Info'">mdi-information</v-icon>
              <v-icon style="font-size:18px !important;"  class="mr-2" color="orange" v-if="item.level === 'Warn'">mdi-alert</v-icon>
              <v-icon style="font-size:18px !important;" class="mr-2" color="red" v-if="item.level === 'Error'">mdi-close-circle</v-icon>
              <v-icon style="font-size:18px !important;" class="mr-2" color="green" v-if="item.level === 'Debug'">mdi-bug</v-icon>
              {{ item.id }}
            </div>
          </td>
          <td style="height:30px !important;" class="table-font-size  py-0">
            {{ item.level }}
          </td>
          <td style="height:30px !important;" class="table-font-size  py-0">
            {{ new Date(item.logged).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric',  second: 'numeric',  hour12: true , fractionalSecondDigits:3 }) }}
          </td>
          <td style="height:30px !important;" class="table-font-size  py-0">
            {{ item.message }}
          </td>
          <td style="height:30px !important;" class="table-font-size  py-0">
            {{item.sessionGuid}}
          </td>
<!--          <td style="height:30px !important;" class="table-font-size px-2 py-0">-->
<!--            {{ item.logger }}-->
<!--          </td>-->
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <template v-if="item.id !== -1">
          <td class="py-2 billings-border-bottom" colspan="8">
            <div class="pa-2">
              <v-card elevation="5">
                <div class="d-flex align-center pt-2 ml-2">
                  <span class="font-weight-medium font-size20 mt-n1 mr-2"> Timestamp:</span>
                  <span class="font-size13"> {{ new Date(logById.logged).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric',  second: 'numeric',  hour12: true, fractionalSecondDigits:3 }) }}</span>
                </div>
                <div class="d-flex align-center pt-2 ml-2">
                  <span class="font-weight-medium font-size20 mt-n1 mr-2">Logger:</span>
                  <span class="font-size13">{{logById?.logger}}</span>
                </div>
                <div class="d-flex align-center pt-2 ml-2">
                  <span class="font-weight-medium font-size20 mt-n1 mr-2">Callsite:</span>
                  <span class="font-size13">{{logById?.callsite}}</span>
                </div>
                <div class="d-flex align-center pt-2 ml-2">
                  <span class="font-weight-medium font-size20 mt-n1 mr-2">Request:</span>
                  <span class="font-size13">{{logById?.request}}</span>
                </div>
                <div class="d-flex align-center pt-2 ml-2">
                  <span class="font-weight-medium font-size20 mt-n1 mr-2">Message:</span>
                  <span class="font-size13">{{logById?.message}}</span>
                </div>
                <v-card v-if="item.level ==='Error'" elevation="5" class="pa-2" style="max-height:100px; overflow-y:auto">
                  <div  class="text-left font-weight-bold">Exception :</div>
                  <p class="text-left">{{logById?.exception}}</p>
                </v-card>
              </v-card>
            </div>
          </td>
        </template>
      </template>
      <template v-slot:footer.prepend>
        <span class="font-weight-bold mx-3">{{ $t('table-legend') }}:</span>
        <i class="mdi mdi-reload mdi-18px d-flex px-2 align-center pointer" @click="filterDataTable('All')">
          <span class="color-black">{{ $t('all') }}</span>
        </i>
        <i class="mdi mdi-information mdi-18px color-green px-2 d-flex align-center pointer" @click="filterDataTable('Info')">
          <span class="color-black">{{ getLogCount('Info') }}</span>
        </i>
        <i class="mdi mdi-alert mdi-18px color-orange px-2 d-flex align-center pointer" @click="filterDataTable('Warn')">
          <span class="color-black">{{ getLogCount('Warn') }}</span>
        </i>
        <i class="mdi mdi-close-circle mdi-18px color-red px-2 d-flex align-center pointer" @click="filterDataTable('Error')">
          <span class="color-black">{{ getLogCount('Error') }}</span>
        </i>
        <i class="mdi mdi-bug mdi-18px color-green px-2 d-flex align-center pointer" @click="filterDataTable('Debug')">
          <span class="color-black">{{ getLogCount('Debug') }}</span>
        </i>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import LogsRepository from '@/data/repositories/v1/LogsRepository';
import { getDataTableFooterProps } from '@/utils/utilities';

export default {
  data () {
    return {
      filterType: '',
      logs: [],
      expanded: [],
      loader: false,
      logById: null,
      selectedLogDate: new Date().toISOString().substr(0, 10),
      headers: [
        { text: 'Id', value: 'id', class: 'height15-black' },
        { text: 'Level', value: 'level', class: 'height15-black' },
        { text: 'Logged', value: 'logged', class: 'height15-black', width: 200 },
        { text: 'Message', value: 'message', class: 'height15-black' },
        { text: 'Session', value: 'sessionGuid', class: 'height15-black', align: 'start pl-3', width: 400 },
        // { text: 'Logger', value: 'errorCode', class: 'height15-black' },
      ],
      logsPerPage: -1,
    };
  },
  mounted () {
  this.fetchLogs();
  },
  methods: {
    getDataTableFooterProps,
    filterDataTable (type) {
      this.filterType = type; // Update the filter type
    },
    onRowsPerPageChange (newValue) {
      this.logsPerPage = newValue;
      this.fetchLogs();

      // Handle additional logic when rows per page changes
    },
    async expand (item) {
      await this.getLogById(item.id);
      if (item === this.expanded[0]) this.expanded = [];
      else this.expanded = [item];
    },
    async fetchLogs () {
      const headers = {
        MaxSystemLogs: this.logsPerPage,
        SelectedDate: this.selectedLogDate,
      };
        this.loader = true;
        const res = await LogsRepository.getLogs(headers);
        this.logs = res.data;
        this.loader = false;
    },
    async getLogById (id) {
        this.loader = true;
        const res = await LogsRepository.getLogById(id);
        this.logById = res.data;
        this.loader = false;
    },
    getLogCount (level) {
      // Ensure logs and aggregatedLogsData are defined before trying to find
      if (!this.logs || !this.logs.aggregatedLogsData) {
        return 0; // Return 0 if the data isn't loaded yet
      }

      const log = this.logs.aggregatedLogsData.find(log => log.level === level);
      return log ? log.counts : 0; // Return the count or 0 if not found
    },
  },
  computed: {
    ...mapState({
      selectedLogsDate: (state) => state.filterbar.selectedLogsDate,
      // logs: (state) => state.logs.logs,
    }),
    ...mapGetters({
      pageHeight: 'pageHeight',
      appBarSearch: 'filterbar/getSearch',
    }),
    filteredLogs () {
      if (!this.filterType) return this.logs.systemLogData;
      if (this.filterType === 'All') {
        return this.logs.systemLogData;
      } else {
        return this.logs.systemLogData.filter((log) => log.level === this.filterType);
      }
    },
  },
  watch: {
    selectedLogsDate: {
      handler (newVal) {
        this.selectedLogDate = newVal;
        this.fetchLogs();
      },
      deep: true,
    },
  },
};
</script>

<style>
.table-font-size {
  font-size: 14px;
}

.v-messages {
  display: none !important;
}

.small i {
  font-size: 18px !important;
}

.small [class*="__ripple"] {
  left: 0;
  display: none;
}
</style>
